import React, { useEffect, useState } from 'react'
import { useCollapse } from 'react-collapsed';
import "./collapse.css"
import Divcard from "./Divcard";
import Card from "./card";
import "./CountryFlags.css";
import axios from 'axios';
import { Buffer } from "buffer";
import { encrypt , decrypt } from '../crypto';

export default function Collapsible({
    index,
    trsvg,
    turkey,
    bundles,
    isCountry,
    expandedIndex,
    setExpandedIndex,
    isHome,
    iso3_code,
}
) {

    const [data, setData] = useState([]);
    const [isPending, setIsPending] = useState(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    const username = process.env.REACT_APP_USERNAME_ESIM;
    const password = Buffer.from(process.env.REACT_APP_PASSWORD_ENCODE_ESIM, 'base64').toString();
    const dataBody = encrypt({
        username,
        password
    });

    const fetchCountries = async () => {
        const conf = {
            method: 'post',
            url: apiUrl + 'AllCountries',
            headers: {
              'Content-Type': 'application/json'
            },
            data: {
                token: dataBody
            },
            params: {
                countries_req: [iso3_code]
            }
          }
        try {
            const result = await axios(conf);
            // console.log(result.data, 'data')
            const encryptedResponse = await result.data
            const decryptedData = decrypt(encryptedResponse);
            setData(decryptedData[0].bundles);
            setIsPending(false);
        } catch (error) {
            console.error(`Error occurred while fetching data: ${error}`);
            setIsPending(false);
        }

    };

        function getUnique(arr, comp1, comp2) {
        // First get unique combinations
        const unique = arr
            .map(e => `${e[comp1]}_${e[comp2]}`)
            .map((e, i, final) => final.indexOf(e) === i && i)
            .filter(e => arr[e])
            .map(e => arr[e]);
    
        // Convert data to GB for comparison
        const convertToGB = (value, unit) => {
            if (unit.toLowerCase() === 'mb') {
                return parseFloat(value) / 1024;
            }
            return parseFloat(value);
        };
    
        // Sort by bundle_category and gprs_limit
        const sortedUnique = unique.sort((a, b) => {
            // Define category priority
            const categoryPriority = {
                'country': 1,
                'region': 2,
                'global': 3
            };
    
            // Compare categories first
            if (categoryPriority[a.bundle_category] !== categoryPriority[b.bundle_category]) {
                return categoryPriority[a.bundle_category] - categoryPriority[b.bundle_category];
            }
    
            // Convert both values to GB for comparison
            const aValueInGB = convertToGB(a.gprs_limit, a.data_unit);
            const bValueInGB = convertToGB(b.gprs_limit, b.data_unit);
    
            // If categories are the same, sort by gprs_limit
            return aValueInGB - bValueInGB;
        });
    
        return sortedUnique;
    }

    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
        isExpanded: expandedIndex === index,
        onCollapseEnd: () => { if (expandedIndex === index) setExpandedIndex(null); },
        easing: 'cubic-bezier(0.5, 0.5, 0.5, 0.3)',
        duration: 250
    });

    
    return (
        <>
            <div className="header" {...getToggleProps()} onClick={() => {
                setExpandedIndex(isExpanded ? null : index)
                if (isCountry && !isHome) fetchCountries()
                else setData(bundles)
            }}>
                <Divcard trsvg={trsvg} turkey={turkey} after="/after@2x.png" isExpanded={isExpanded} />
            </div>
            <div className='content' {...getCollapseProps()}>
                <div className="card-grid">
                    {isHome && isCountry && data && getUnique(data, 'validity', 'gprs_limit').map((bundle, i) => {

                        return (
                            <Card
                                index={`${index}-${i}`}
                                trsvg={trsvg}
                                category={bundle.bundle_category}
                                region_name={bundle.region_name}
                                title={bundle.bundle_marketing_name}
                                price={bundle.subscriber_price}
                                validity={bundle.validity}
                                country_code={bundle.country_code}
                                gprs_limit={bundle.gprs_limit}
                                data_unit={bundle.data_unit}
                                country_iso2_code={bundle.country_iso2_code}
                                isCountry={isCountry}
                                bundle={bundle}
                            />
                        )
                    }
                    )}
                    {!isHome && !isPending && isCountry && data && getUnique(data, 'validity', 'gprs_limit').map((bundle, i) => {

                        return (
                            <Card
                                index={`${index}-${i}`}
                                trsvg={trsvg}
                                category={bundle.bundle_category}
                                region_name={bundle.region_name}
                                title={bundle.bundle_marketing_name}
                                price={bundle.subscriber_price}
                                validity={bundle.validity}
                                country_code={bundle.country_code}
                                gprs_limit={bundle.gprs_limit}
                                data_unit={bundle.data_unit}
                                country_iso2_code={bundle.country_iso2_code}
                                isCountry={isCountry}
                                bundle={bundle}
                            />
                        )
                    }
                    )}
                    {!isHome && isCountry && isPending && <div className="country-flags1" style={{ height: "200px", alignItems: "center" }}><div class="loader"></div></div>}
                    {!isCountry && data && data.map((bundle, i) => {

                        return (
                            <Card
                                index={`${index}-${i}`}
                                trsvg={trsvg}
                                title={bundle.bundle_marketing_name}
                                price={bundle.subscriber_price}
                                validity={bundle.validity}
                                country_code={bundle.country_code}
                                gprs_limit={bundle.gprs_limit}
                                data_unit={bundle.data_unit}
                                country_iso2_code={bundle.country_iso2_code}
                                isCountry={isCountry}
                                bundle={bundle}
                            />
                        )
                        // }
                    }
                    )}
                </div>
            </div>
        </>
    )
}

